/*
 * 업무구분 : 계약관리
 * 화면 명 : MSPPM954D
 * 화면 설명: 보험계약기본사항 > 미래예시 > 미래연금 : 연금보험 기본형(개시전)
 * 접근권한: FC, 지점장 
 * 작 성 일: 2023.03.08
 * 작 성 자: 서영화
 */
<template>
  <ur-page-container class="msp" :show-title="false" :topButton="false">
    <ur-box-container  direction="column" alignV="start" class="msp-pm-wrap">
      <!-- 검색조회 start -->
      <mo-list-item class="expand_bottomline expand_topline"> 
        <!-- msp-expand 에 맨 첫번째 값만 펼침 일 겨우 첫번째에 expanded 넣어주세요! -->
        <msp-expand ref="expandMSPPM54D" 
                    btn-area-first title-first expand-only-btn class="mo-list-expand" btn-area-class="fexTy3 align-item-start">
          <template #title>
            <div class="list-item__contents">
              <div class="list-item__contents__title">
                <span class="name fs19rem">예시기준</span>
              </div>
            </div>
          </template>
          <template #btn>
              <mo-button class="link-arrow down"></mo-button>
          </template>
          <template #content>
            <div class="full-list mt20 mbm20">
              <div class="list-item__contents gray-box pa2024">
                <div class="list-item__contents__info fexTy2">
                  <span class="min120 crTy-bk7 ">연금개시 준비금</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.prcAnutBgnPrsv }}원</span>
                </div>
                
                <!-- (=전환특약가입금액) -->
                <!-- @notice : 23.05.12 인터페이스에서 잘못된 데이터 리턴되어서 해당 부분 주석처리 -->
                <!-- <div class="list-item__contents__info fexTy2 mt4">
                  <template>
                    <span v-if="!contBasMatrVO.prdtNm.includes('더블찬스')" class="min120 crTy-bk7">상속형연금 준비금</span>
                    <span v-else class="min120 crTy-bk7">연금전환가입금액</span>
                  </template>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.prcAnutCnvrEntAmt }}원</span>
                </div> -->

                <!-- (=실제연금재원) -->
                <div v-if="!contBasMatrVO.prdtNm.includes('더블찬스')" class="list-item__contents__info fexTy2 mt4">
                  <span class="min120 crTy-bk7">종신/확정 연금재원</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.prcAnutFrscAmt }}원</span>
                </div>

                <div class="list-item__contents__info fexTy2 mt4">
                  <span class="min120 crTy-bk7">기준이율 유형</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.irtKndCd }}</span>
                </div>
                <div class="list-item__contents__info fexTy2 mt4">
                  <span class="min120 crTy-bk7">기준이율</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.stndIrt }}</span>
                </div>
                <div class="list-item__contents__info fexTy2 mt4">
                  <span class="min120 crTy-bk7">예시적용이율</span>
                  <span class="ml20 fs16rem">{{ anutInsrBasTypVO.aplcIrt }}</span>
                </div>

                <div class="input_area">
                  <!-- 조기연금전환 -->
                  <div class="list-item__contents__info mt20">
                    <span class="min120 crTy-bk7">조기연금전환</span>
                    <ur-box-container alignV="start" componentid="" direction="column" class="row-box">
                      <span class="label-title w100px">{{ earlAnutPsbYn }} </span>
                      <msp-bottom-select :items="earlAnutCnvrs" v-model="earlAnutCnvr" :disabled="!isEnabledEarlAnutCnvr"
                                         :itemValue="'value'" :itemText="'text'" class="ns-dropdown-sheet" underline bottom-title="조기연금전환" closeBtn placeholder=""/>
                    </ur-box-container>
                  </div>
                  <!-- 지급형태변경대상 -->
                  <div class="list-item__contents__info mt20">
                    <span class="min80 crTy-bk7">지급형태변경대상</span>
                    <msp-bottom-select :items="pytShpChgObjs" v-model="pytShpChgObj" :disabled="!isEnabledPytShpChgObj"
                                       :itemValue="'value'" :itemText="'text'" class="ns-dropdown-sheet" underline bottom-title="지급형태변경대상" closeBtn placeholder=""/>
                  </div>
                  <!-- 연금개시지급금 -->
                  <div class="list-item__contents__info mt20">
                    <span class="min80 crTy-bk7">연금개시지급금</span>
                    <ur-box-container alignV="start" componentid="" direction="row" class="fexTy3 full">
                      <div class="row-text w50"> 
                        <msp-bottom-select :items="anutBgnPymAmts" v-model="anutBgnPymAmt" :disabled="!isEnabledAnutBgnPymAmt" @input="fn_ProcessData"
                                           :itemValue="'value'" :itemText="'text'" class="ns-dropdown-sheet pr30" underline bottom-title="연금개시지급금" closeBtn placeholder=""/>
                        <span class="right-text fs17rem">%</span>
                      </div>
                      <span class="fs16rem">{{ oldagPlanFdAmt }}원</span>
                    </ur-box-container>
                  </div>   
                               
                </div>

                <div class="select_area">
                  <div class="list-item__contents__info mt20">
                    <span class="min80 crTy-bk7">선택유형1</span>
                    <msp-bottom-select :items="slctTyps01" v-model="slctTyp01.value" :disabled="!isEnabledSlctTyp01" @input="fn_SetSlctTypData(slctTyp01.value, 1)"
                                       :itemText="'text'" class="ns-dropdown-sheet" underline bottom-title="선택유형1" closeBtn placeholder=""/>
                  </div>
                  <div class="list-item__contents__info mt20">
                    <span class="min80 crTy-bk7">선택유형2</span>
                    <msp-bottom-select :items="slctTyps01" v-model="slctTyp02.value" :disabled="!isEnabledSlctTyp01" @input="fn_SetSlctTypData(slctTyp02.value, 2)"
                                       :itemValue="'value'" :itemText="'text'" class="ns-dropdown-sheet" underline bottom-title="선택유형2" closeBtn placeholder=""/>
                  </div>
                  <div class="list-item__contents__info mt20">
                    <span class="min80 crTy-bk7">선택유형3</span>
                    <msp-bottom-select :items="slctTyps01" v-model="slctTyp03.value" :disabled="!isEnabledSlctTyp01" @input="fn_SetSlctTypData(slctTyp03.value, 3)"
                                        :itemValue="'value'" :itemText="'text'" class="ns-dropdown-sheet" underline bottom-title="선택유형3" closeBtn placeholder=""/>
                  </div>
                  <ur-box-container alignV="start" componentid="ur_box_container_006" direction="row" class="insert-area pal0 mt20 mb10">
                    <mo-button class="ns-btn-round white" @click="fn_Submit">조회</mo-button>
                  </ur-box-container>  
                </div>
              </div>
            </div>
          </template>
        </msp-expand>
      </mo-list-item> 
      <!-- 검색조회 end -->
      
      <template  v-if="!isNoData">
        <!-- radio 버튼 영역 -->
        <ur-box-container class="area-bb flex-sc">
          <div class="txt-sum">
            총 <strong>{{totalCount}}</strong> 건 
            <span class="fs14rem crTy-bk7 fwn">(단위:원)</span>
          </div>
          <div class="wrap-btn mr-m10">
            <mo-radio-wrapper v-model="radioButton" class="ns-radio-list2">
              <mo-radio value="1" small>목록</mo-radio>
              <mo-radio value="2" small>표</mo-radio>
            </mo-radio-wrapper>
            <mo-button icon="msp-ptmode" class="ns-btn-round black sm" @click="fn_open">크게보기</mo-button>
          </div>
        </ur-box-container>


        <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-list-area pb-0">
          <!-- 표 모드 -->
          <ur-box-container v-if="radioButton==='2'" class="sticky-pd">
            <div class="sticky-area">
              <div class="table-area sticky-t02" ref="tableArea" @scroll="fn_checkScroll">
                <table class="table row-type ws_N">
                  <thead>
                    <tr>
                      <th rowspan="2">연도</th>
                      <th rowspan="2">연령</th>
                      <th colspan="4">기본가입 연금지급 (종신형 10년 보증)</th>
                      <th colspan="3">연금지급형태 변경특약 예정이율형</th>
                    </tr>
                    <tr>
                      <th>기본연금</th>
                      <th>장수우대</th>
                      <th>축하금</th>
                      <th>합계</th>
                      <th>선택유형1</th>
                      <th>선택유형2</th>
                      <th>선택유형3</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in anutInsrBasTypVO.bpanutOccuYmAmtVO01" :key="i">
                      <td> {{item.occuYr}} </td>
                      <td> {{item.age}} </td>
                      <td class="aR"> {{item.prcDoubleAmount01}} </td>
                      <td class="aR"> {{item.prcDoubleAmount02}} </td>
                      <td class="aR"> {{item.prcDoubleAmount03}} </td>
                      <td class="aR"> {{item.prcDoubleAmount04}} </td>
                      <td class="aR"> {{item.prcDoubleAmount05}} </td>
                      <td class="aR"> {{item.prcDoubleAmount06}} </td>
                      <td class="aR"> {{item.prcDoubleAmount07}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <button class="icon-right2" @click="fn_scrollToRight(1)" ref="iconRight" v-show="isShowChevron"><i></i></button>
            <button class="icon-down2 pos-fixed" @click="fn_scrollToDown(1)" ref="iconDown" v-show="isShowChevronDown"><i></i></button>
            </div>
          </ur-box-container>

          <!-- 목록 모드 -->
          <ur-box-container v-if="radioButton==='1'" alignV="start" componentid="" direction="column" class="ns-info-list">
            <mo-list :list-data="anutInsrBasTypVO.bpanutOccuYmAmtVO01">
              <template #list-item="{item}">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__title">
                      <span class="fwn crTy-bk7 fs17rem mr10">연도</span>
                      <span class="fs19rem fwb">{{ item.occuYr }}</span>
                      <em class="align-self-center"></em>
                      <span class="fwn crTy-bk7 fs17rem mr10">연령</span>
                      <span class="fs19rem fwb">{{ item.age }}</span>
                    </div>
                    <div class="list-item__contents__info pr0">
                      <div class="info_box mt10">
                        <div class="full">
                          <span class="label-title mr10">기본연금</span>
                          <span>{{ item.prcDoubleAmount01 }}</span>
                          <em></em>
                          <span class="label-title mr10">장수우대</span>
                          <span>{{ item.prcDoubleAmount02 }}</span>
                        </div>
                        <div class="full">
                          <span class="label-title mr10">축하금</span>
                          <span>{{ item.prcDoubleAmount03 }}</span>
                          <em></em>
                          <span class="label-title mr10">합계</span>
                          <span>{{ item.prcDoubleAmount04 }}</span>
                        </div>
                        <div class="full">
                          <span class="label-title mr10">선택연금1</span>
                          <span>{{ item.prcDoubleAmount05 }}</span>
                          <em></em>
                          <span class="label-title mr10">선택연금2</span>
                          <span>{{ item.prcDoubleAmount06 }}</span>
                        </div>
                        <div class="full">
                          <span class="label-title mr10">선택연금3</span>
                          <span>{{ item.prcDoubleAmount07 }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>
        </ur-box-container>
        <!-- 데이터 목록 end -->
      </template>
      <template v-else>
        <!-- 데이터 없음 -->
        <ur-box-container  alignV="start" componentid="ur_box_container_001" direction="column" class="ns-contract-list no-data">
          <mo-list-item>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span>데이터가 없습니다.</span>
              </div>
            </div>
          </mo-list-item>
        </ur-box-container>
      </template>

      <!-- 크게보기 모드 -->
      <mo-bottom-sheet ref="nsbottomsheet2" class="ns-bottom-sheet ptmode">
        <mo-top-bar>
          <mo-button class="btn_popup_back"></mo-button>
          <div slot="nav" class="icon-wrapper" @click="fn_close">
            <mo-icon color="white" borderless>drawer</mo-icon>
          </div>
          <div class="ui-gnb-title__main">미래연금</div>
        </mo-top-bar>

        <div class="content-area">
          <ur-box-container alignV="start" direction="column" class="ns-list-area">
            <ur-box-container>
              <div class="sticky-area">
                <div class="table-area sticky-t02" ref="tableAreaPop" @scroll="fn_checkScroll">
                  <table class="table row-type ws_N">
                    <thead>
                      <tr>
                        <th rowspan="2">연도</th>
                        <th rowspan="2">연령</th>
                        <th colspan="4">기본가입 연금지급 (종신형 10년 보증)</th>
                        <th colspan="3">연금지급형태 변경특약 예정이율형</th>
                      </tr>
                      <tr>
                        <th>기본연금</th>
                        <th>장수우대</th>
                        <th>축하금</th>
                        <th>합계</th>
                        <th>선택유형1</th>
                        <th>선택유형2</th>
                        <th>선택유형3</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, i) in anutInsrBasTypVO.bpanutOccuYmAmtVO01" :key="i">
                        <td> {{item.occuYr}} </td>
                        <td> {{item.age}} </td>
                        <td class="aR"> {{item.prcDoubleAmount01}} </td>
                        <td class="aR"> {{item.prcDoubleAmount02}} </td>
                        <td class="aR"> {{item.prcDoubleAmount03}} </td>
                        <td class="aR"> {{item.prcDoubleAmount04}} </td>
                        <td class="aR"> {{item.prcDoubleAmount05}} </td>
                        <td class="aR"> {{item.prcDoubleAmount06}} </td>
                        <td class="aR"> {{item.prcDoubleAmount07}} </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button class="icon-right" @click="fn_scrollToRight(2)" ref="iconRight" v-show="isShowChevron2"><i></i></button>
                <button class="icon-down" @click="fn_scrollToDown(2)" ref="iconDown" v-show="isShowChevronDown2"><i></i></button>
              </div>
            </ur-box-container>
          </ur-box-container>
        </div>
      </mo-bottom-sheet>
    </ur-box-container>

    

    

  </ur-page-container>
  
</template>

<script>

import pmConstants from '@/config/constants/pmConstants' 

export default {

  name: 'MSPPM954D', 
  screenId: 'MSPPM954D', 
  components: {},//componets,

  props:{
    pMSPPM953DData: {
      type: Object,
      default: {}
    },
    pIsSearched: Boolean
  },//props

  data () {
    return {
      title: '미래연금 : 연금보험 기본형(개시전)',

      /**
       * 화면 변수
       */ 
      isNoData: false,
      isCreated: true,                 // 최초생성 여부 
      isExtended: false,               // 예시기준 펼침 여부
      totalCount: 0,                   // 연금보험 기본형(개시전) 총 개수 

      contBasMatrVO: {},              // 계약메인데이터(MSPPM931M) 

      /**
       * 조회 변수
       */  
      anutInsrBasTypVO: {},            // 연금보험 기본형(개시전) 데이터 
      anutInsrBasTypList: [],          // 연금보험 기본형(개시전) 목록 데이터 

      earlAnutPsbYn: '',               // 조기연금가능여부
      earlAnutCnvrs: [],               // 조기연금전환 list 데이터 
      earlAnutCnvr: '',                // 선택된 조기연금전환 value 데이터 (anutBgnYmd)
      isEnabledEarlAnutCnvr: false,    // 조기연금전환 접근 가능여부

      anutBgnPymAmts:[],               // 연금개시지급금 list 데이터 
      anutBgnPymAmt: '',               // 선택된 연금개시지급금 value 데이터 (oldagPlanFdRto)
      oldagPlanFdAmt: '',              // 연금개시지급금 금액(=노후설계자금금액) 
      isEnabledAnutBgnPymAmt: false,   // 연금개시지급금 접근 가능여부

      pytShpChgObjs: [],               // 지급형태변경대상 list 데이터
      pytShpChgObj: '',                // 선택된 지급형태변경대상 value 데이터 
      isEnabledPytShpChgObj: false,    // 지급형태변경대상 접근 가능여부

      // 선택유형1,2,3 list 데이터 
      slctTyps01: [{value: 0, anutPymTypCd: '00', anutPymTypCdNm: '선택안함', jliveScCd: '00', guaDcdPrdYys: '0', anutPymExpnShpYn: 'N', text: '선택안함'}], 
      
      slctTyp01: {},                  // 선택된 선택유형1 Object 데이터 
      slctTyp02: {},                  // 선택된 선택유형2 Object 데이터 
      slctTyp03: {},                  // 선택된 선택유형3 Object 데이터 
      isEnabledSlctTyp01: true,       // 선택유형1,2,3 접근여부

      isExistedSlct: true,            // 선택유형 존재여부

      radioButton: '2',               // 라디오 버튼 선택 값
      isShowChevron: false,           // affordance 가로 버튼 노출여부
      isShowChevronDown: false,       // affordance 세로 버튼 노출여부
      isShowChevron2: false,          // 크게보기 affordance 버튼 노출여부
      isShowChevronDown2: false,      // 크게보기 affordance 버튼 노출여부


    }
  },//data

  created () {

    console.log('MSPPM954D created');

    this.contBasMatrVO = this.getStore('pmStore').getters.getState.contInfo   // MSPPM931M 계약 데이터 
    this.isCreated     = false
    
    this.fn_Init()
    
  },//created

  mounted () {

    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

  },//mounted

  activated () {
    
    console.log('MSPPM954D activated!');

    if(this.isCreated){

      console.log('MSPPM954D activated start init');

      if(this.pIsSearched){

        // 초기화 
        this.isNoData   = false
        this.totalCount = 0
        this.anutInsrBasTypVO   = this.pMSPPM953DData
        this.anutInsrBasTypList = this.pMSPPM953DData.bpanutOccuYmAmtVO05  // 목록데이터

        // 데이터 세팅
        this.fn_SetAnutData()

        console.log('MSPPM954D searched init');
        return
      }

      this.fn_Init()
    }
    this.isCreated = true
  },
  deactivated() {
    
    /**
     * 예시기준 펼침 초기화
     * @notice keep alive 때문에 click trigger 필요 
     */
    if(this.$refs.expandMSPPM54D.$el.classList.contains('open')) {
      this.$refs.expandMSPPM54D.$el.classList.remove('open') 
      this.$refs.expandMSPPM54D.$el.querySelector('div.accordion-btn-area > div > div:nth-child(2) > button').click()
    }
    
  },

  watch: {},//watch

  computed: {},//computed

  methods: {
   
   /**
    * @description 선택된 선택유형 데이터를 세팅한다.
    * @param       {Number} targetVal 선택된 유형 데이터의 value
    * @param       {String} type      선택유형 종류
    */
    fn_SetSlctTypData(targetVal, type){
      
      // 선택유형1,2,3 의 list = slctTyps01
      let typeList = this.slctTyps01

      // 선택된 선택유형 데이터 value와 동일한 데이터 찾기
      let target   = typeList.filter((item) => item.value === targetVal )

      // 선택된 선택유형 object 데이터 저장 
      this['slctTyp0'+ type] = Object.assign({}, target[0])

    },

   /**
    * @description 초기화한다.
    */
    fn_Init(){
      console.log('MSPPM954D init');

      // 초기화 
      this.anutInsrBasTypVO = {}
      this.totalCount       = 0
      this.isNoData         = false

      this.slctTyps01 = [{value: 0, anutPymTypCd: '00', anutPymTypCdNm: '선택안함', jliveScCd: '00', guaDcdPrdYys: '0', anutPymExpnShpYn: 'N', text: '선택안함'}]
      
      // 데이터 세팅 
      this.anutInsrBasTypVO = this.pMSPPM953DData                     // MSPPM953D 미래연금 데이터  
      this.anutBgnPymAmts   = pmConstants.FUE_ANUT_OLDAG_PLAN_FD_CD
      this.anutBgnPymAmt    = this.anutBgnPymAmts[0].value
      this.pytShpChgObjs    = pmConstants.FUE_ANUT_PYM_SHP_CHG_OBJ_CD
      this.pytShpChgObj     = this.pytShpChgObjs[0].value
      
      
      // 연금개시지급금 데이터 가공 
      this.fn_ProcessData() 
      
      let targetSlcts = this.anutInsrBasTypVO.bpanutPymTypInfoVO  // 선택유형 list 데이터 

      // 콜백데이터에 선택유형 데이터가 존재하는가.
      if(targetSlcts){
        this.isExistedSlct = true
      }else{
        this.isExistedSlct = false 
      }

      // 선택유형1,2,3 list 데이터 설정 
      if(this.isExistedSlct){

        targetSlcts.forEach((item, index) => {

          let tempData = { 
            value: index + 1,                                 
            anutPymTypCd: item.anutPymTypCd,          // 연금지급유형코드
            anutPymTypCdNm: item.anutPymTypCdNm,      // 연금지급유형코드명 
            jliveScCd: item.jliveScCd,                // 연생구분코드
            guaDcdPrdYys: item.guaDcdPrdYys,          // 보증확정기간년수
            anutPymExpnShpYn: item.anutPymExpnShpYn,  // 연금지급확장형태여부
            text: item.anutPymTypCdNm                 // 연금지급유형코드명
          }

          // 선택유형 list 데이터 저장 
          this.slctTyps01.push(Object.assign({}, tempData)) 
        })

      }else{
        this.isEnabledSlctTyp01 = false 
      }

      // 선택된 선택유형1,2,3 설정
      for(let i = 0; i < 3; i++){

        let type     = {}   // 선택된 선택유형 object 데이터 
        let typeList = []   // 선택유형 list 데이터 

        // 선택유형 list 데이터 설정 
        typeList = JSON.parse(JSON.stringify(this.slctTyps01))

        let target = this.anutInsrBasTypVO.bpanutPymTypInfoVO01[i+1]

        if(target.anutPymTypCd === '00' && target.jliveScCd === '00'){
          type = typeList[0]
        }else{

          type = typeList[0]

          typeList.forEach((item) => {

            if(target.anutPymTypCd === item.anutPymTypCd && target.jliveScCd === item.jliveScCd && target.guaDcdPrdYys === item.guaDcdPrdYys && target.anutPymExpnShpYn === item.anutPymExpnShpYn){
              type = {}
              type = item 
            }

          })

        }

        // 선택된 선택유형 데이터 저장  
        this['slctTyp0'+ (i+1)] = Object.assign({}, type)

      }


      // 연금개시지급금 데이터 세팅 
      // '노설노복지급여부' 로 '연금개시지급금 접근 가능여부' 판단
      if(this.anutInsrBasTypVO.okinPymYn === 'N'){ 
        this.isEnabledAnutBgnPymAmt = false
      }else{
        this.isEnabledAnutBgnPymAmt = true 
      }

      // 선택된 연금개시지급금 value 세팅 
      this.anutBgnPymAmts.forEach((item) => {
        if(item.value === String(this.anutInsrBasTypVO.oldagPlanFdRto))
          this.anutBgnPymAmt = item.value
      })

      // 노후설계자금 금액 세팅 
      this.oldagPlanFdAmt = String(this.anutInsrBasTypVO.oldagPlanFdAmt.doubleAmount)
      this.oldagPlanFdAmt = this.$commonUtil.numberWithCommas(this.oldagPlanFdAmt)
      
      
      // 지급형태변경대상 데이터 세팅 
      // '지급형태변경유무' 로 '지급형태변경대상 접근 가능여부' 판단
      if(this.anutInsrBasTypVO.pymShpChgExnex === 'N'){ 
        this.isEnabledPytShpChgObj = false
      }else{
        this.isEnabledPytShpChgObj = true 
      }

      // 조기연금전환 데이터 세팅  
      let earlAnutCnvrSize

      if(this.anutInsrBasTypVO.bpearlAnutBgnYearVO != null) 
        earlAnutCnvrSize = this.anutInsrBasTypVO.bpearlAnutBgnYearVO.length

      if(earlAnutCnvrSize > 0){

        this.earlAnutPsbYn = '가능'
        this.earlAnutCnvrs.slice(0)

        // 조기연금전환 list 세팅 
        let data = this.anutInsrBasTypVO.bpearlAnutBgnYearVO
        data.forEach((item) => {
          let temp = {value: item.anutBgnYr, text: item.anutBgnYr + '년'}
          this.earlAnutCnvrs.push(temp)
        })

        // 선택된 조기연금전환 value 세팅 
        this.earlAnutCnvr = this.earlAnutCnvrs[this.earlAnutCnvrs.length -1].value

        this.isEnabledEarlAnutCnvr = true 

      }else{

        this.earlAnutPsbYn = '불가능'
        this.earlAnutCnvrs.slice(0)

        // 조기연금전환 list 세팅 
        if(this.anutInsrBasTypVO.anutBgnYmd != null){

          let data = this.anutInsrBasTypVO.anutBgnYmd
          let temp = {value: data.substr(0, 4), text: data.substr(0, 4) + '년'}

          this.earlAnutCnvrs.push(temp)

          // 선택된 조기연금전환 value 세팅 
          this.earlAnutCnvr = this.earlAnutCnvrs[0].value

        }
        this.isEnabledEarlAnutCnvr = false 
      }

      // 데이터 세팅 
      this.fn_SetAnutData()

    },

   /**
    * @description 예시기준 및 목록 데이터 세팅한다.
    */
    fn_SetAnutData(){

      // 목록 데이터 가공 
      this.anutInsrBasTypList = this.anutInsrBasTypVO.bpanutOccuYmAmtVO01  // 목록데이터

      // 예시기준 데이터 가공 
      this.anutInsrBasTypVO.prcAnutFrscAmt    = this.$commonUtil.numberWithCommas(this.anutInsrBasTypVO.anutFrscAmt.doubleAmount)    // 종신/확정 연금재원(=실제연금재원)
      this.anutInsrBasTypVO.prcAnutBgnPrsv    = this.$commonUtil.numberWithCommas(this.anutInsrBasTypVO.anutBgnPrsv.doubleAmount)    // 연금개시 준비금
      this.anutInsrBasTypVO.prcAnutCnvrEntAmt = this.$commonUtil.numberWithCommas(this.anutInsrBasTypVO.anutCnvrEntAmt.doubleAmount) // 전환특약가입금액
      this.anutInsrBasTypVO.stndIrt           = Number(this.anutInsrBasTypVO.stndIrt)/1000 + " %"                                    // 기준이율
      this.anutInsrBasTypVO.aplcIrt           = Number(this.anutInsrBasTypVO.aplcIrt)/1000 + " %"                                    // 예시적용이율

      if(this.$bizUtil.isEmpty(this.anutInsrBasTypList) || this.anutInsrBasTypList.length === 0){
        this.isNoData = true 
      }else{

        this.totalCount = this.anutInsrBasTypList.length // 미래연금(연금보험 기본형:개시전) 목록 데이터 

        this.anutInsrBasTypList.forEach((item) => {

          item.prcDoubleAmount01 = this.$commonUtil.numberWithCommas(item.anpsn01.doubleAmount) // 기본연금
          item.prcDoubleAmount02 = this.$commonUtil.numberWithCommas(item.anpsn02.doubleAmount) // 장수우대
          item.prcDoubleAmount03 = this.$commonUtil.numberWithCommas(item.anpsn03.doubleAmount) // 축하금
          item.prcDoubleAmount04 = this.$commonUtil.numberWithCommas(item.anpsn04.doubleAmount) // 합계
          item.prcDoubleAmount05 = this.$commonUtil.numberWithCommas(item.anpsn05.doubleAmount) // 선택유형1
          item.prcDoubleAmount06 = this.$commonUtil.numberWithCommas(item.anpsn06.doubleAmount) // 선택유형2
          item.prcDoubleAmount07 = this.$commonUtil.numberWithCommas(item.anpsn07.doubleAmount) // 선택유형3

        })

      }

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.anutInsrBasTypVO.bpanutOccuYmAmtVO01.length > 0){
          this.isShowChevronDown = true
          this.isShowChevron = true
        } else{
          this.isShowChevronDown = false
          this.isShowChevron = false
        }
      })

    },

   /**
    * @description 연금개시지급금 데이터를 가공한다.
    */
    fn_ProcessData(){

      // 연금개시지급금 = 연금개시준비금 * 비율 
      let tempOldagAmt    = parseInt(this.anutInsrBasTypVO.anutBgnPrsv.doubleAmount * (Number(this.anutBgnPymAmt) / 100))
      this.oldagPlanFdAmt = this.$commonUtil.numberWithCommas(tempOldagAmt)

    },

    /**
     * @description 조회에 필요한 파라미터 설정 및 초기화 후, 서비스를 호출한다.
     */
    fn_Submit(){

      let lv_InputParam = {}

      lv_InputParam.retPrscpScCd     = '1'
      lv_InputParam.contrNm          = this.anutInsrBasTypVO.contrNm
      lv_InputParam.contrRrn         = this.anutInsrBasTypVO.contrRrn

      lv_InputParam.earlAnutPsbYn    = this.anutInsrBasTypVO.earlAnutPsbYn
      lv_InputParam.aisdNm           = this.anutInsrBasTypVO.aisdNm
      lv_InputParam.aisdRrn          = this.anutInsrBasTypVO.aisdRrn
      lv_InputParam.aisdSxdsCd       = this.anutInsrBasTypVO.aisdSxdsCd
      lv_InputParam.aisdAge          = this.anutInsrBasTypVO.aisdAge

      lv_InputParam.oldagPlanFdRto   = this.anutBgnPymAmt 
      lv_InputParam.anutBgnYmd       = this.earlAnutCnvr
      lv_InputParam.anutPymObjScCd   = this.pytShpChgObj

      lv_InputParam.bpanutPymTypInfoVO01 = []

      // 선택유형4 조회 데이터 저장 
      // 조회할때 필수조건이라서 조회할 때 넣어줘야한다.
      let slctTyp04 = {}
      slctTyp04.anutPymTypCd     = this.anutInsrBasTypVO.bpanutPymTypInfoVO01[0].anutPymTypCd
      slctTyp04.jliveScCd        = this.anutInsrBasTypVO.bpanutPymTypInfoVO01[0].jliveScCd
      slctTyp04.guaDcdPrdYys     = this.anutInsrBasTypVO.bpanutPymTypInfoVO01[0].guaDcdPrdYys
      slctTyp04.anutPymExpnShpYn = this.anutInsrBasTypVO.bpanutPymTypInfoVO01[0].anutPymExpnShpYn
      lv_InputParam.bpanutPymTypInfoVO01.push(slctTyp04)

      // 선택유형1,2,3 조회 데이터 저장 
      if(this.isExistedSlct){

        for(let i = 0; i < 3; i++){
          let tempObj = {}
          let type    = {}

          // 선택된 선택유형 object 데이터 설정 
          type = this['slctTyp0'+ (i+1)] 
        
          tempObj.anutPymTypCd     = type.anutPymTypCd                  // 연금지급유형코드 
          tempObj.jliveScCd        = type.jliveScCd                     // 연생구분코드
          tempObj.guaDcdPrdYys     = type.guaDcdPrdYys                  // 보증확정기간년수
          tempObj.anutPymExpnShpYn = type.anutPymExpnShpYn              // 연금지급확장형태여부

          lv_InputParam.bpanutPymTypInfoVO01.push(tempObj)

        }

      }

      this.isSearched = true 
      
      this.$emit('condSumbit', lv_InputParam)

    },

   /**
    * @description 화면에 노출될 예시기준 조건을 세팅한다.
    */
    fn_SetCondInfo(){
      // 연금개시지급금 데이터 가공 
      this.fn_ProcessData() 

      // 연금개시지급금 데이터 세팅 
      // '노설노복지급여부' 로 '연금개시지급금 가능여부' 판단
      if(this.anutInsrBasTypVO.okinPymYn === 'N'){ 
        this.isEnabledAnutBgnPymAmt = false
      }else{
        this.isEnabledAnutBgnPymAmt = true 
      }
      
      // 지급형태변경대상 데이터 세팅 
      // '지급형태변경유무' 로 '지급형태변경대상 가능여부' 판단
      if(this.anutInsrBasTypVO.pymShpChgExnex === 'N'){ 
        this.isEnabledPytShpChgObj = false
      }else{
        this.isEnabledPytShpChgObj = true 
      }

      // 조기연금전환 데이터 세팅 
      // 조기연금예시인 경우
      if (this.anutInsrBasTypVO.earlAnutPsbYn === 'Y') { 
        this.anutBgnPymAmt  = this.anutBgnPymAmts[0].value         
        this.oldagPlanFdAmt = '0'                                  

        this.pytShpChgObj            = this.pytShpChgObjs[1].value 
        this.isEnabledAnutBgnPymAmt  = false
        this.isEnabledPytShpChgObj   = false
      }
    },

   /**
    * @description 크게보기 오픈.
    */
    fn_open() {
      this.$refs.nsbottomsheet2.open();

      // affordance 버튼 제어
      this.$nextTick(() => {
        if(this.anutInsrBasTypVO.bpanutOccuYmAmtVO01.length > 0){
          this.isShowChevronDown2 = true
          this.isShowChevron2 = true
        }else{
          this.isShowChevronDown2 = false
          this.isShowChevron2 = false
        }
      })
    },

   /**
    * @description 크게보기 모드 닫기.
    */
    fn_close() {
      this.$refs.nsbottomsheet2.close();
    },

   /**
    * @description 표 모드 스크롤 이벤트.
    */
    fn_checkScroll(value) {
      if(value === 1) {       // 표 모드
        if(this.$refs.tableArea.scrollLeft > 10) {
          this.isShowChevron = false
          this.isShowChevronDown = false
        }
      }
      else {        // 크게보기 모드
        if(this.$refs.tableAreaPop.scrollLeft > 10 || this.$refs.tableAreaPop.scrollTop > 10 ) { 
          this.isShowChevron2 = false
          this.isShowChevronDown2 = false
        }
      }
    },

   /**
    * @description Right affordance 버튼 이벤트.
    */
    fn_scrollToRight(value) {
      let tableArea
      if(value === 1) {
        this.isShowChevron = false
        this.isShowChevronDown = false
        tableArea = this.$refs.tableArea;
      } else {
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        tableArea = this.$refs.tableAreaPop;
      }
      const scrollAmount = 200;
      const scrollDuration = 1000;
      let startingPosition = tableArea.scrollLeft;
      let targetPosition = startingPosition + scrollAmount;
      this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'horizontal');
     },

   /**
    * @description Down affordance 버튼 이벤트.
    */
    fn_scrollToDown(value) {
      if(value === 1) {                 // 표 모드
        this.isShowChevron = false
        this.isShowChevronDown = false
        this.$emit('scrollDown')
      } else {                          // 크게보기 모드
        this.isShowChevron2 = false
        this.isShowChevronDown2 = false
        const tableArea = this.$refs.tableAreaPop
        const scrollAmount = 200
        const scrollDuration = 1000
        const startingPosition = tableArea.scrollTop;
        const targetPosition = startingPosition + scrollAmount;
        this.animateScroll(tableArea, startingPosition, targetPosition, scrollDuration, 'vertical');
      }
    },

   /**
    *  @description 표에 있는 affordance 버튼 제어
    */
    fn_ctrAffordance() {
      this.isShowChevron = false
      this.isShowChevronDown = false
    },
    
    animateScroll(element, start, target, duration, direction) {
      const startTime = performance.now();
      const animate = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = this.easeInOutCubic(progress);
        if (direction === 'vertical') {
          element.scrollTop = start + (target - start) * easedProgress;
        } else if (direction === 'horizontal') {
          element.scrollLeft = start + (target - start) * easedProgress;
        }
        if (elapsedTime < duration) {
          requestAnimationFrame(animate);
          
        }
      };
      animate(startTime);
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t -2) + 1;
    }
 },// methods

}//export default


</script>